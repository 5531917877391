.media-container .media-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;

  &:hover {
    a {
      .scaled-img {
        cursor: zoom-in;
        transform: scale(1.1);

        -webkit-filter: grayscale(100%);
        filter: blur(1px);
        filter: grayscale(100%);
      }
      &::after {
        opacity: 1;
      }
    }
  }
  a {
    position: relative;
    width: 100%;
    height: 200px;
    display: block;
    overflow: hidden;

    &::after {
      // @extend .text-white;
      font-family: "Font Awesome 5 Pro";
      font-size: 2.5em;
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      content: ("\f302");
      transition: all .2s ease-in-out;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    .scaled-img {
      position: relative;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .15s ease-in-out;
    }

    &.large-img {
      height: 430px;
    }
  }
}




.ekko-lightbox {
  display: -ms-flexbox!important;
  display: flex!important;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 0!important
}

.ekko-lightbox-container {
  position: relative
}

.ekko-lightbox-container>div.ekko-lightbox-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%
}

.ekko-lightbox iframe {
  width: 100%;
  height: 100%
}

.ekko-lightbox-nav-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex
}

.ekko-lightbox-nav-overlay a {
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  transition: opacity .5s;
  color: #fff;
  font-size: 30px;
  z-index: 1
}

.ekko-lightbox-nav-overlay a>* {
  -ms-flex-positive: 1;
  flex-grow: 1
}

.ekko-lightbox-nav-overlay a>:focus {
  outline: none
}

.ekko-lightbox-nav-overlay a span {
  padding: 0 30px
}

.ekko-lightbox-nav-overlay a:last-child span {
  text-align: right
}

.ekko-lightbox-nav-overlay a:hover {
  text-decoration: none
}

.ekko-lightbox-nav-overlay a:focus {
  outline: none
}

.ekko-lightbox-nav-overlay a.disabled {
  cursor: default;
  visibility: hidden
}

.ekko-lightbox a:hover {
  opacity: 1;
  text-decoration: none
}

.ekko-lightbox .modal-dialog {
  display: none
}

.ekko-lightbox .modal-footer {
  text-align: left
}

.ekko-lightbox-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center
}

.ekko-lightbox-loader>div {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center
}

.ekko-lightbox-loader>div>div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: a 2s infinite ease-in-out
}

.ekko-lightbox-loader>div>div:last-child {
  animation-delay: -1s
}

.modal-dialog .ekko-lightbox-loader>div>div {
  background-color: #333
}

@keyframes a {
  0%, to {
    transform: scale(0);
    -webkit-transform: scale(0)
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1)
  }
}
